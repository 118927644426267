/* eslint-disable @typescript-eslint/camelcase */
import { ChangeEmbargoRequestInterface } from "../contracts/EmbargoRequest";

export class AddEmbargoApiRequest implements ChangeEmbargoRequestInterface {
  embargo_name: string;
  origin_id: string;
  destination_id: string;
  service_type: string;
  product_type: string;
  commodity_type: string;
  max_weight: number;
  start_date_time: string;
  end_date_time: string;

  constructor(
    embargo_name: string,
    origin_id: string,
    destination_id: string,
    service_type: string,
    product_type: string,
    commodity_type: string,
    max_weight: number,
    start_date_time: string,
    end_date_time: string
  ) {
    this.embargo_name = embargo_name;
    this.origin_id = origin_id;
    this.destination_id = destination_id;
    this.service_type = service_type;
    this.product_type = product_type;
    this.commodity_type = commodity_type;
    this.max_weight = max_weight;
    this.start_date_time = start_date_time;
    this.end_date_time = end_date_time;
  }

  public toJSON(): string {
    return JSON.stringify({
      embargo_name: this.embargo_name,
      origin_id: this.origin_id,
      destination_id: this.destination_id,
      service_type: this.service_type,
      product_type: this.product_type,
      commodity_type: this.commodity_type,
      max_weight: this.max_weight,
      start_date_time: this.start_date_time,
      end_date_time: this.end_date_time
    });
  }
}

export class EditEmbargoApiRequest implements ChangeEmbargoRequestInterface {
  embargo_id: number;
  embargo_name: string;
  origin_id: string;
  destination_id: string;
  service_type: string;
  product_type: string;
  commodity_type: string;
  max_weight: number;
  start_date_time: string;
  end_date_time: string;

  constructor(
    embargo_id: number,
    embargo_name: string,
    origin_id: string,
    destination_id: string,
    service_type: string,
    product_type: string,
    commodity_type: string,
    max_weight: number,
    start_date_time: string,
    end_date_time: string
  ) {
    this.embargo_id = embargo_id;
    this.embargo_name = embargo_name;
    this.origin_id = origin_id;
    this.destination_id = destination_id;
    this.service_type = service_type;
    this.product_type = product_type;
    this.commodity_type = commodity_type;
    this.max_weight = max_weight;
    this.start_date_time = start_date_time;
    this.end_date_time = end_date_time;
  }

  public toJSON(): string {
    return JSON.stringify({
      embargo_id: this.embargo_id,
      embargo_name: this.embargo_name,
      origin_id: this.origin_id,
      destination_id: this.destination_id,
      service_type: this.service_type,
      product_type: this.product_type,
      commodity_type: this.commodity_type,
      max_weight: this.max_weight,
      start_date_time: this.start_date_time,
      end_date_time: this.end_date_time
    });
  }
}
